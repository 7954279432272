<template>
    <div id="construction">
        <div class="container">
            <div class="title">EscapeGaming.io</div>
            <div class="subtitle">Coming soon</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Construction'
    }
</script>

<style lang="scss" scoped>
    #construction {
        width: 100%;
        height: 100vh;
        background-color: black;
        text-align: center;
        display: flex;

        .container {
            margin: auto;
            position: relative;

            .title {
                font-family: 'Goldman';
                font-size: 9vw;
                color: white;
            }

            .subtitle {
                font-family: 'Lato';
                font-size: 5vw;
                color: white;
            }
        }
    }
</style>